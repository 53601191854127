import React from 'react';
import Slider from 'react-slick';

import '../styles/testimonial.css';


const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 3000,
    swipeToSlide: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 2
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="mt-4">
      
	<section class="testimonial-section">
		<div class="large-container">
			<div class="sec-title">
				<h2>What Our core client say ?</h2>
			</div>

			<div class="testimonial-carousel owl-carousel owl-theme">

				<div class="testimonial-block">
					<div class="inner-box">
						<div class="text">I love the convenience of having so many pickup locations. It made my travel plans flexible and stress-free. Great experience with e-Rental!</div>
						<div class="info-box">
							<div class="thumb"><img src="http://192.168.56.1:3000/images/image.png" alt={''} /></div>
							<h4 class="name">Mahfuz Riad</h4>
							<span class="designation">Facebook CEO</span>
						</div>
					</div>
				</div>

				<div class="testimonial-block">
					<div class="inner-box">
						<div class="text">The whole city tour was fantastic! The driver was knowledgeable and friendly, and we got to see all the major landmarks in comfort. Highly recommend!</div>
						<div class="info-box">
							<div class="thumb"><img src="http://192.168.56.1:3000/images/image.png" alt={''} /></div>
							<h4 class="name">Mahfuz Riad</h4>
							<span class="designation">Oracle CEO</span>
						</div>
					</div>
				</div>

				<div class="testimonial-block">
					<div class="inner-box">
						<div class="text">Unlimited miles rental was a game-changer for our road trip. No worries about extra charges, and the car performed beautifully. Thank you, e-Rental!</div>
						<div class="info-box">
							<div class="thumb"><img src="http://192.168.56.1:3000/images/image.png" alt={''} /></div>
							<h4 class="name">Mahfuz Riad</h4>
							<span class="designation">Google CEO</span>
						</div>
					</div>
				</div>
			</div>

			<div class="thumb-layer paroller">
				<figure class="image"><img src="http://192.168.56.1:3000/images/user-thumbs.png" alt={''} /></figure>
			</div>
		</div>
	</section>

    </Slider>
  );
};

export default Testimonial;
