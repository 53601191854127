import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import '../styles/servicebox.css';
import { Link } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import { BASE_URL } from '../config/config';

const ServicesItem = () => {
  const { data: services, isPending, error } = useFetch(`${BASE_URL}/services`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [services]);
  return (
    <>
      {isPending && <h6 className="text-center">Loading....</h6>}
      {error && <h6 className="text-center">{error}</h6>}

      {services &&
        services.map((item, index) => <ServiceBox item={item} key={index} />)}
    </>
  );
};

const ServiceBox = ({ item }) => {
  return (
    <Col lg="2" md="4" sm="6" className="mb-2">
      <div className="service__box">
        <div className="service-box-content">
        <span className="mb-3 d-inline-block d-flex justify-content-center align-items-center">
          <i class={item.icon}></i>
        </span>
        <h6 className="mb-3 d-inline-block d-flex justify-content-center align-items-center">
          <Link to={`/service-details/${item.id}`}>{item.title}</Link>
        </h6>
        <p className="section__description">
          {item.description.length > 0
            ? item.description.substr(0, 0)
            : item.description}
        </p>
        </div>
      </div>
    </Col>
  );
};

export default ServicesItem;
