import React from 'react';
import { Row, Col } from 'reactstrap';
import '../styles/about-section.css';

const AboutSection = () => {
  return (
    <Row>
      <Col lg="6" md="6">
        <div className="about__section__content">
          <h6 className="section__subtitle">About Us</h6>
          <h2 className="section__title">Welcome to Car Rent Service</h2>
          <p className="section__description">
          Welcome to e-Rental, your premier destination for car rental solutions tailored to meet all your 
          transportation needs. At e-Rental, we believe that car rentals should be seamless, convenient, and 
          customer-centric, ensuring you have a smooth journey from start to finish.{' '}
          </p>

          <div className="about__section-checkbox d-flex align-items-center">
            <p className="section__description d-flex align-items-center gap-4">
              <i class="ri-checkbox-circle-line"></i> Convenience.
            </p>
            <p className="section__description d-flex align-items-center gap-4">
              <i class="ri-checkbox-circle-line"></i> Transparency.
            </p>
          </div>
          <div className="about__section-checkbox d-flex align-items-center  gap-8">
            <p className="section__description d-flex align-items-center gap-4">
              <i class="ri-checkbox-circle-line"></i> Sustainability
            </p>
            <p className="section__description d-flex align-items-center gap-4">
              <i class="ri-checkbox-circle-line"></i> Customer-Centric Service.
            </p>
          </div>
        </div>
      </Col>

      <Col lg="6" md="6">
        <div className="about__section-img">
          <img
            src="../images/marcedes-offer.d177a4fe.png"
            alt=""
            className="w-100"
          />
        </div>
      </Col>
    </Row>
  );
};

export default AboutSection;
